<template>
  <div class="step_container">
    <h1 class="step_title">ENTITY DETAIL 公司信息</h1>
    <a-form-model
      layout="horizontal"
      :label-col="labelCol"
      :model="firstForm"
      :wrapper-col="wrapperCol"
      ref="entity_step_one"
      :rules="rules"
    >
      <div class="step_question_container border_bot_red">
        <h2 class="step_question_title">ENTITY DETAIL 公司信息</h2>
        <div class="mutiple_form_layout">
          <a-form-model-item
            :style="GetScreenStyle.width45percent"
            prop="entity_name"
            label="Entity Name 单位/公司名称"
          >
            <a-input
              :disabled='disabled'
              @change="
                ({target: {value}}) => {
                  handleInput(value, 'entity_name');
                }
              "
              :value="entity_name"
            >
            </a-input>
          </a-form-model-item>
          <a-form-model-item
            :style="GetScreenStyle.width45percent"
            prop="entity_register_num"
            label="Registration No. 注册号码"
          >
            <a-input
              :disabled='disabled'
              @change="
                ({target: {value}}) => {
                  handleInput(value, 'entity_register_num');
                }
              "
              :value="entity_register_num"
            >
            </a-input>
          </a-form-model-item>
          <!-- <a-form-model-item
            :style="GetScreenStyle.width45percent"
            prop="entity_phone"
            label="Phone Number 公司电话"
          >
            <a-input
              :disabled='disabled'
              @change="
                ({target: {value}}) => {
                  handleInput(value, 'entity_phone');
                }
              "
              :value="entity_phone"
            >
            </a-input>
          </a-form-model-item> -->
        </div>
        <!-- <div class="mutiple_form_layout">
          <a-form-model-item
            :style="GetScreenStyle.width45percent"
            prop="entity_register_num"
            label="Registration No. 注册号码"
          >
            <a-input
              :disabled='disabled'
              @change="
                ({target: {value}}) => {
                  handleInput(value, 'entity_register_num');
                }
              "
              :value="entity_register_num"
            >
            </a-input>
          </a-form-model-item>
          <a-form-model-item
            :style="width45percent"
            prop="entity_tax_num"
            label="Tax Number 公司税号"
          >
            <a-input
              :disabled='disabled'
              @change="
                ({target: {value}}) => {
                  handleInput(value, 'entity_tax_num');
                }
              "
              :value="entity_tax_num"
            >
            </a-input>
          </a-form-model-item>
        </div> -->
        <div class="mutiple_form_layout">
          <a-form-model-item
            :style="GetScreenStyle.width100percent"
            prop="entity_industry_field"
            label="What industry is your organisation in? 您的公司所属哪个行业？"
          >
            <a-input
              :disabled='disabled'
              @change="
                ({target: {value}}) => {
                  handleInput(value, 'entity_industry_field');
                }
              "
              :value="entity_industry_field"
            >
            </a-input>
            <div class="textarea_desc">I.e. Construction, import/export, commercial property investment etc. 例如：建筑，进出口，商业地产投资等</div>
          </a-form-model-item>
        </div>
        <!-- <div class="mutiple_form_layout">
          <a-form-model-item
            :style="width100percent"
            prop="entity_job_desc"
            label="What does your organisation do in the above industry? 您的公司在上述行业中做什么？"
          >
            <a-input
              :disabled='disabled'
              @change="
                ({target: {value}}) => {
                  handleInput(value, 'entity_job_desc');
                }
              "
              :value="entity_job_desc"
            >
            </a-input>
            <div class="textarea_desc">I.e. Health food export, wine wholesaler, cosmetics import etc. 例如：保健食品出口，葡萄酒批发商，化妆品进口等</div>
          </a-form-model-item>
        </div> -->
      </div>
      <!-- 地址信息 -->
      <div class="step_question_container border_bot_red">
        <h2 class="step_question_title">
          Address And Contact Details 地址信息
        </h2>
        <div>
          <div class="mutiple_form_layout">
            <a-form-model-item
              :style="GetScreenStyle.width60percent"
              prop="entity_address"
              label="Business Address(No Po BOX)公司地址"
            >
            <BaseAddress
              prop="entity_address"
              :disabled="disabled"
              :value="entity_address"
              @handleChange='(prop,value) => {
                handleInput(value,prop)
              }'
            />
            </a-form-model-item>
            <BaseCountrySelection
              :style="GetScreenStyle.width30percent"
              label="Country 国家地区"
              prop="entity_country"
              :disabled="disabled"
              :selectedVal='entity_country'
              @handleChange="
                  (value, variable) => {
                    handleInput(variable, value);
                  }
                "
              valType='code'
            >
            </BaseCountrySelection>
          </div>
          <div class="mutiple_form_layout">
            <a-form-model-item
              :style="GetScreenStyle.width30percent"
              prop="entity_suburb"
              label="Suburb 地区"
            >
              <a-input
                :disabled='disabled'
                @change="
                  ({target: {value}}) => {
                    handleInput(value, 'entity_suburb');
                  }
                "
                :value="entity_suburb"
              >
              </a-input>
            </a-form-model-item>
            <a-form-model-item
              :style="GetScreenStyle.width30percent"
              prop="entity_city"
              label="City 城市"
            >
              <a-input
                :disabled='disabled'
                @change="
                  ({target: {value}}) => {
                    handleInput(value, 'entity_city');
                  }
                "
                :value="entity_city"
              >
              </a-input>
            </a-form-model-item>
            <a-form-model-item
              :style="GetScreenStyle.width30percent"
              prop="entity_postcode"
              label="Postcode 邮政编码"
            >
              <a-input
                :disabled='disabled'
                @change="
                  ({target: {value}}) => {
                    handleInput(value, 'entity_postcode');
                  }
                "
                :value="entity_postcode"
              >
              </a-input>
            </a-form-model-item>
          </div>
          <div class="mutiple_form_layout">
            <a-form-model-item
              :style="GetScreenStyle.width45percent"
              prop="entity_email"
              label="E-mail 电子邮箱"
            >
              <a-input
                :disabled='disabled'
                @change="
                  ({target: {value}}) => {
                    handleInput(value, 'entity_email');
                  }
                "
                :value="entity_email"
              >
              </a-input>
            </a-form-model-item>
            <a-form-model-item
              :style="GetScreenStyle.width45percent"
              placeholder="if Any 如有"
              label="WeChat 微信(If Any 如有)"
            >
              <a-input
                :disabled='disabled'
                @change="
                  ({target: {value}}) => {
                    handleInput(value, 'entity_wechat');
                  }
                "
                :value="entity_wechat"
              >
              </a-input>
            </a-form-model-item>
          </div>
          <BaseAddressVertify
            :disabled='disabled'
            radio_var="entity_addressProveType"
            :address_prove_type="entity_addressProveType"
            address_prove_file_list_str="entity_address_file_list"
            :address_prove_file_list="entity_address_file_list"
            @handleChange="handleChange"
            @FileValidate="validateField"
          ></BaseAddressVertify>
        </div>
      </div>
      <!-- 工作情况 -->
      <div class="step_question_container border_bot_green">
        <h2 class="step_question_title">Entity Tax Information 公司税务信息</h2>
        <div class="step_upload_box marginT_10">
          <div class="step_sub_title">
            <a-form-model-item
              prop="entity_is_nz_taxer"
              label="Are you an NZ Tax Resident? 您是否是新西兰税务居民?"
            >
              <a-radio-group
              class="modify_antd_radio_big"
                name="entity_is_nz_taxer"
                :disabled='disabled'
                :value="entity_is_nz_taxer"
                @change="handleRadio"
              >
                <a-radio :value="true">Yes </a-radio>
                <a-radio :value="false">No </a-radio>
              </a-radio-group>
            </a-form-model-item>
            <a-form-model-item
              v-if="entity_is_nz_taxer"
              :style="GetScreenStyle.width60percent"
              prop="entity_nz_tax_number"
              label="请提供IRD/Tax Number 税号"
            >
              <a-input
                :disabled='disabled'
                @change="
                  ({target: {value}}) => {
                    handleInput(value, 'entity_nz_tax_number');
                  }
                "
                :value="entity_nz_tax_number"
              >
              </a-input>
            </a-form-model-item>
            <div
               v-if="entity_is_nz_taxer == false"
              :style="GetScreenStyle.width100percent"
              label="请提供其他国家税号"
            >
              <a-tabs
                v-model="taxActiveKey"
                :hide-add="getTaxPanesLength >= 3 || disabled"
                type="editable-card"
                @edit="taxPaneEdit"
              >
                <a-tab-pane
                  v-for="item in entity_tax_panes"
                  :key="item.key"
                  :tab="item.title"
                  :closable="item.closeable"
                >
                  <div class="mutiple_form_layout">
                    <BaseCountrySelection
                      :style="GetScreenStyle.width45percent"
                      label="Country of Tax Residence 税务国家"
                      :required='true'
                      :disabled="disabled"
                      :selectedVal='item.tax_country'
                      @handleChange="
                          (variable, value) => {
                            handlePaneChange(value, 'entity_tax_panes', item.key, 'tax_country');
                          }
                        "
                      valType='code'
                    >
                    </BaseCountrySelection>
                    <a-form-model-item
                      required
                      :style="GetScreenStyle.width45percent"
                      label="Tax Number 税号"
                    >
                      <a-input
                        :disabled='disabled'
                        @change="
                          ({ target: { value } }) => {
                            handlePaneChange(value, 'entity_tax_panes', item.key, 'tax_number');
                          }
                        "
                        :value="item.tax_number"
                      >
                      </a-input>
                    </a-form-model-item>
                  </div>
                </a-tab-pane>
              </a-tabs>
            </div>
            <a-form-model-item v-if="getTaxIncludeUS">
              <AUploadFile
                :disabled='disabled'
                title="If you are a US taxer you need to download and fill the form below "
                prop="entity_us_taxer_file_list"
                :fileList="entity_us_taxer_file_list"
                @handleFileChange="handleFileList"
              >
                <a
                  class="
                border-black-85
                py-2
                px-4
                border
                my-2
                inline-block
                rounded-md
                shadow-md
                text-sm
            "
                  href="https://public-nzsky-files.s3-ap-southeast-2.amazonaws.com/fw9.pdf"
                  download
                >
                  Click this
                </a>
              </AUploadFile>
            </a-form-model-item>
          </div>
        </div>
      </div>
      <div class="step_question_container border_bot_red">
        <h2 class="step_question_title">The status of the Entity 公司的状态</h2>
        <a-form-model-item
          label="Please choose the status of the Entity"
          prop="entity_status_radio"
        >
          <a-radio-group
            class="modify_antd_radio"
            :disabled='disabled'
            name="entity_status_radio"
            :value="entity_status_radio"
            @change="handleRadio"
          >
            <a-radio :style="radioStyle" value="Active_Non_Financial_Entity">
              Active Non-Financial Entity 积极的非金融实体
            </a-radio>
            <a-radio :style="radioStyle" value="Passive_Non_Financial_Entity">
              Passive Non-Financial Entity (complete Section 3C) 被动非金融实体
            </a-radio>
            <a-radio :style="radioStyle" value="Financial_Institution">
              Financial Institution 金融机构
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <!-- radio option1 -->
        <div v-if="entity_status_radio === 'Active_Non_Financial_Entity'">
          Active Non-Financial Entity (ANFE): a likely status for entities that derive the majority
          of their income, within a reporting period, through the course of an active trade or
          business (for example: retail or service-based business).<br />
          活跃的非金融实体（ANFE）：在报告期内，通过活跃的交易或业务（例如：零售或基于服务的业务）获得其大部分收入的实体的可能状态。
          <a-form-model-item prop="Active_Non_Financial_Entity_check">
            <a-checkbox
              :disabled='disabled'
              class="antd_checkbox"
              name="Active_Non_Financial_Entity_check"
              :checked="Active_Non_Financial_Entity_check"
              @change="handleCheck"
            >
              Read & Consent 已读确认
            </a-checkbox>
          </a-form-model-item>
        </div>
        <!-- radio option2 -->
        <div v-if="entity_status_radio === 'Passive_Non_Financial_Entity'">
          <div>
            Passive Non-Financial Entity (PNFE): main source of income is passive (for example: your
            entity has ≥ 50% passive income AND ≥ 50% passive income generating assets (in the
            previous reporting period).<br />
            被动非金融实体（PNFE）：主要收入来源是被动的（例如：您的实体被动收入≥50％，被动创收资产≥50％（上一财年报告期）。
          </div>
          <a-form-model-item prop="Passive_Non_Financial_Entity_check">
            <a-checkbox
              :disabled='disabled'
              class="antd_checkbox"
              name="Passive_Non_Financial_Entity_check"
              :checked="Passive_Non_Financial_Entity_check"
              @change="handleCheck"
            >
              Read & Consent 已读确认
            </a-checkbox>
          </a-form-model-item>
          <a-tabs
            v-model="controlling_person_panes_activeKey"
            :hideAdd="getControlPaneShow || disabled"
            type="editable-card"
            @edit="onEdit"
          >
            <a-tab-pane
              v-for="pane in controlling_person_panes"
              :key="pane.key"
              :forceRender="true"
              :tab="pane.title"
              :closable="pane.closeable"
            >
              <div class="p-2 bg-gray-200">
                <a-form-model-item
                required
                :style="GetScreenStyle.width45percent" label="Full Name 全名">
                  <a-input
                    :disabled='disabled'
                    @change="
                      ({ target: { value } }) => {
                        handlePaneChange(value, 'controlling_person_panes', pane.key, 'fullname');
                      }
                    "
                    :value="pane.fullname"
                  >
                  </a-input>
                </a-form-model-item>
                <a-form-model-item
                required
                >
                  <a-radio-group
                    class="modify_antd_radio"
                    :disabled='disabled'
                    :value="pane.position"
                    @change="
                      ({ target: { value } }) => {
                        handlePaneChange(value, 'controlling_person_panes', pane.key, 'position');
                      }
                    "
                  >
                    <a-radio :style="radioStyle" value="control_by_ownership">
                      Control By Ownership 被动资产拥有者
                    </a-radio>
                    <a-radio :style="radioStyle" value="control_by_means">
                      Control By Other Means 通过其他方式控制
                    </a-radio>
                    <a-radio :style="radioStyle" value="senior_managing_Offical">
                      Senior Managing Offical 高级管理人员
                    </a-radio>
                  </a-radio-group>
                </a-form-model-item>
                <div class="mutiple_form_layout">
                  <BaseCountrySelection
                    :style="GetScreenStyle.width45percent"
                    label="Country of Tax Residence 税务国家"
                    :disabled="disabled"
                    :selectedVal='pane.tax_country'
                    @handleChange="
                        (prop, value) => {
                          handlePaneChange(
                            value,
                            'controlling_person_panes',
                            pane.key,
                            'tax_country'
                          );
                        }
                      "
                    valType='code'
                  >
                  </BaseCountrySelection>
                  <a-form-model-item
                  required
                  :style="GetScreenStyle.width45percent" label="Tax Number 税号">
                    <a-input
                      :disabled='disabled'
                      :value="pane.tax_number"
                      @change="
                        ({ target: { value } }) => {
                          handlePaneChange(
                            value,
                            'controlling_person_panes',
                            pane.key,
                            'tax_number'
                          );
                        }
                      "
                    />
                  </a-form-model-item>
                </div>
              </div>
            </a-tab-pane>
          </a-tabs>
        </div>
        <!-- radio option3 -->
        <div v-if="entity_status_radio === 'Financial_Institution'">
          Financial Institution (FI): a Custodian Institution, Depository Institution, Investment
          Entity, or Specified Insurance Company. Apart from more obvious entities such as banks,
          this can also include non-bank deposit takers, collective investment entities, mutual
          funds, private equity funds, hedge funds, investment managers and advisors, and certain
          brokers and trusts (including some managed family trusts).<br />
          金融机构（FI）：托管机构，存款机构，投资实体或指定保险公司。
          除了银行等更明显的实体外，还可以包括非银行存款接受者，集体投资实体，共同基金，私募股权基金，对冲基金，投资经理和顾问，以及某些经纪人和信托（包括一些管理的家庭信托）。
          <a-form-model-item prop="Financial_Institution_check">
            <a-checkbox
              :disabled='disabled'
              class="antd_checkbox"
              name="Financial_Institution_check"
              :checked="Financial_Institution_check"
              @change="handleCheck"
            >
              Read & Consent 已读确认
            </a-checkbox>
          </a-form-model-item>
        </div>
      </div>
    </a-form-model>
  </div>
</template>

<script>
import BaseCountrySelection from '@/components/BaseCountrySelection'
import BaseAddressVertify from '@/components/BaseAddressVertify.vue'
import { mapActions, mapState, mapGetters } from 'vuex'
import AUploadFile from '@/components/AUploadFile'
import rules from '@/assets/rules/entityOneRules.js'
import cnFilter from '@/utils/chineseFilter.js'
import BaseAddress from '@/components/BaseAddress.vue'

export default {
  components: {
    BaseAddressVertify,
    AUploadFile,
    BaseCountrySelection,
    BaseAddress
  },

  created () {
    this.rules = rules
  },
  beforeRouteLeave (to, from, next) {
    if (this.disabled) {
      next()
    } else {
      this.$refs.entity_step_one.validate(valid => {
        if (valid) {
          next()
        } else {
          next()
        }
      })
    }
  },
  methods: {
    validateField (field) {
      this.$refs.entity_step_one.validateField(field)
    },
    handlePaneChange (value, obj, key, child) {
      const index = this[obj].findIndex(item => item.key === key)
      this.ChangeInnerAction({ obj, index, child, value: cnFilter(value) })
    },
    handleFileList (variable, file) {
      this.ChangeFormAction({ content: variable, value: file })
    },
    handleOtherTaxCountry (val, param) {
      const index = this.entity_panes.findIndex(item => item.key === param)
      this.entity_panes[index].content.other_tax_contry = val
      this.ChangeObjectAction({ content: 'entity_panes', value: this.entity_panes })
    },
    handleOtherTaxNum (e, param) {
      const val = e.target.value
      const index = this.entity_panes.findIndex(item => item.key === param)
      this.entity_panes[index].content.other_tax_num = val
      this.ChangeObjectAction({ content: 'entity_panes', value: this.entity_panes })
    },
    handleCheck (e) {
      const { checked, name } = e.target
      this.ChangeFormAction({ content: name, value: checked })
    },
    onEdit (targetKey, action) {
      if (!this.disabled) {
        this[action](targetKey)
      }
    },
    taxPaneEdit (targetKey, action) {
      if (!this.disabled) {
        this['Tax' + action](targetKey)
      }
    },
    Taxadd () {
      const pane = this.entity_tax_panes
      const newPane = {
        title: 'Tax 税务 #' + (this.getTaxPanesLastKey + 1),
        key: this.getTaxPanesLastKey + 1,
        closeable: true,
        tax_country: '',
        tax_number: ''
      }
      pane.push(newPane)
      this.ChangeObjectAction({ content: 'entity_tax_panes', value: pane })
      this.taxActiveKey = this.getTaxPanesLastKey
    },
    Taxremove (targetKey) {
      let activeKey = this.taxActiveKey
      let _panes = {}
      let lastIndex
      this.entity_tax_panes.forEach((pane, i) => {
        if (pane.key === targetKey) {
          lastIndex = i - 1
        }
      })
      _panes = this.entity_tax_panes.filter(pane => pane.key !== targetKey)
      if (lastIndex >= 0) {
        activeKey = this.entity_tax_panes[lastIndex].key
      } else {
        activeKey = this.entity_tax_panes[0].key
      }
      this.taxActiveKey = activeKey
      this.ChangeObjectAction({ content: 'entity_tax_panes', value: _panes })
    },
    add () {
      const _panes = this.controlling_person_panes
      const newPane = {
        title: 'Person 控制人' + (this.getPersonPanesLastKey + 1),
        fullname: '',
        key: this.getPersonPanesLastKey + 1,
        closeable: true,
        position: '',
        tax_country: '',
        tax_number: ''
      }
      _panes.push(newPane)
      this.ChangeObjectAction({ content: 'controlling_person_panes', value: _panes })
      this.controlling_person_panes_activeKey = this.getPersonPanesLastKey
    },
    remove (targetKey) {
      let activeKey = this.controlling_person_panes_activeKey
      let _panes = {}
      let lastIndex
      this.controlling_person_panes.forEach((pane, i) => {
        if (pane.key === targetKey) {
          lastIndex = i - 1
        }
      })
      _panes = this.controlling_person_panes.filter(pane => pane.key !== targetKey)
      if (lastIndex >= 0) {
        activeKey = this.controlling_person_panes[lastIndex].key
      } else {
        activeKey = this.controlling_person_panes[0].key
      }
      this.controlling_person_panes_activeKey = activeKey
      this.ChangeObjectAction({ content: 'controlling_person_panes', value: _panes })
    },
    handleChange (variable, value) {
      this.ChangeFormAction({ content: variable, value: cnFilter(value) })
    },
    handleInput (value, content) {
      this.ChangeFormAction({ content, value: cnFilter(value) })
    },
    handleRadio (e) {
      const { name, value } = e.target
      this.ChangeFormAction({ content: name, value })
    },

    ...mapActions('entityStepOne', ['ChangeFormAction', 'ChangeObjectAction', 'ChangeInnerAction'])
  },
  data () {
    return {
      controlling_person_panes_activeKey: 1,
      taxActiveKey: 1,
      radioStyle: {
        lineHeight: '30px',
        width: '100%',
        marginBottom: '10px'
      },
      width30percent: {
        width: '30%'
      },
      width60percent: {
        width: '60%'
      },
      width45percent: {
        width: '45%'
      },
      width100percent: {
        width: '100%'
      },
      labelCol: { span: 24 },
      wrapperCol: { span: 24 }
    }
  },
  computed: {
    firstForm () {
      return this.$store.state.entityStepOne
    },
    getControlPaneShow () {
      return this.controlling_person_panes.length > 3
    },
    getPersonPanesLastKey () {
      return +this.controlling_person_panes[this.controlling_person_panes.length - 1].key || 0
    },
    getLastPanesKey () {
      return this.entity_panes[this.entity_panes.length - 1].key
    },
    ...mapGetters('layout', ['GetScreenStyle']),
    ...mapGetters({
      disabled: 'login/getComplainceActive'
    }),
    ...mapGetters('entityStepOne', ['getTaxPanesLength', 'getTaxPanesLastKey', 'getTaxIncludeUS']),
    ...mapState('entityStepOne', [
      'entity_name',
      'entity_phone',
      'entity_register_num',
      'entity_tax_num',
      'entity_industry_field',
      'entity_job_desc',
      'entity_address',
      'entity_city',
      'entity_country',
      'entity_postcode',
      'entity_email',
      'entity_wechat',
      'entity_suburb',
      'entity_addressProveType',
      'Active_Non_Financial_Entity_check',
      'Financial_Institution_check',
      'controlling_person_panes',
      'Passive_Non_Financial_Entity_check',
      'entity_address_file_list',
      'entity_is_nz_taxer',
      'entity_status_radio',
      'entity_nz_tax_number',
      'entity_tax_panes',
      'entity_us_taxer_file_list'
    ]),
    ...mapState('countrySelection', ['popularCountryList'])
  }
}
</script>

<style scoped>
.step_upload_box {
  border-bottom: 1px solid rgba(0, 0, 0, 0.45);
}
.mutiple_form_layout {
  display: flex;
  justify-content: space-between;
}
.step_title {
  font-size: 22px;
  text-align: center;
  padding: 30px 0;
  font-weight: 300;
}

.step_notice_text {
  font-size: 13px;
  font-weight: 300;
  color: rgba(0, 0, 0, 0.65);
}
.step_checkbox_layout {
  margin-top: 20px;
}
.ant-form-item-label .ant-form-item-required {
  font-size: 16px;
  color: red;
}
.step_sub_title {
  margin: 20px 0;
  font-size: 15px;
  color: rgba(0, 0, 0, 0.65);
  font-weight: 500;
}
.step_ul_style {
  list-style-type: inside;
  list-style-type: disc;
  padding-left: 20px;
  font-size: 16px;
  font-weight: 300;
}
.step_ul_style li {
  line-height: 24px;
}

.marginT_10 {
  margin-top: 10px;
}
.border_bot_red {
  border-bottom: 2px solid #f32e59;
}
.border_bot_green {
  border-bottom: 2px solid #2baf6f;
}
.modify_antd_radio_big >>> .ant-radio-inner {
  width: 26px;
  height: 26px;
}
.modify_antd_radio_big >>> .ant-radio-inner::after {
  width: 12px;
  left: 6px;
  top: 6px;
  height: 12px;
}
.modify_antd_radio >>> .ant-radio-inner {
  width: 26px;
  height: 26px;
}
.modify_antd_radio >>> .ant-radio-wrapper {
    white-space: pre-wrap;
    display: flex;
}
.modify_antd_radio >>> .ant-radio-inner::after {
  width: 12px;
  left: 6px;
  top: 6px;
  height: 12px;
}
.ant-form >>> label {
  font-size: 15px;
}
.antd_checkbox >>> .ant-checkbox-wrapper {
  font-size: 15px;
}
.antd_checkbox >>> .ant-checkbox-inner {
  width: 30px;
  height: 30px;
}
.antd_checkbox >>> .ant-checkbox-inner::after {
  width: 9px;
  height: 18px;
}
</style>
